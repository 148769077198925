<template>
  <div class="bank-card" :class="{'closed': closed}">
    <div class="img">
      <img :src="'/bn/' + mainImage()" alt="" @click="$emit('img-click')">
      <div class="bank-num" v-if="!card.closed">
        <span>{{ $store.getters.LANG === 'ua' ? 'НОМЕР КАРТКИ' : 'CARD NUMBER' }}</span>
        <span>{{ card.bank_number }}</span>
      </div>
    </div>
    <div class="description">
      <h1>{{ card.title[$store.getters.LANG] }}</h1>
      <p>{{ card.description[$store.getters.LANG] }}</p>
      <a :href="card.bank_link" target="_blank" v-if="!card.closed">{{ card.bank_link }}</a>
    </div>
  </div>
</template>

<script>

export default {
  name: "BankCard",
  props: {
    card: {type: Object, default: () => { return {images: '', title: {ua: '', en: ''}, description: {ua: '', en: ''}, bank_number: '', bank_link: ''} }},
    closed: {type: Boolean, default: false}
  },
  methods: {
    mainImage() {
      const images = this.card.images.split(',')
      if (images.length) return images[0]
      return ''
    }
  }
}
</script>

<style scoped lang="scss" src="../assets/scss/bank_card.scss"></style>
