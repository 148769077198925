<template>
  <section class="list">
    <div class="inner">
      <template v-if="activeBanks.length">
        <h2>{{ text.active[$store.getters.LANG] }}</h2>
        <div class="banks">
          <BankCard v-for="(bank, i) in activeBanks" :card="bank" :key="i"></BankCard>
        </div>
      </template>
      <h2>{{ text.list[$store.getters.LANG] }}</h2>
      <div class="needs">
        <div>
          <h3>{{ text.needed[$store.getters.LANG] }} <br><b class="perm">{{ text.constant[$store.getters.LANG] }}</b></h3>
          <span v-for="(item, i) in permanentsList[$store.getters.LANG]" :key="i">{{ item }}</span>
        </div>
        <div>
          <h3>{{ text.needed[$store.getters.LANG] }} <br><b class="impr">{{ text.urgent[$store.getters.LANG] }}</b></h3>
          <span v-for="(item, i) in importantsList[$store.getters.LANG]" :key="i">{{ item }}</span>
        </div>
      </div>
      <template v-if="closedBanks.length">
        <h2>{{ text.closed[$store.getters.LANG] }}</h2>
        <div class="banks closed">
          <BankCard v-for="(bank, i) in closedBanks" :card="bank" :closed="true" :key="i" @img-click="onImgClick(bank.images)"></BankCard>
        </div>
      </template>
    </div>
    <transition name="img-info" appear>
      <div class="img-container" @click.self="closeImg" v-if="imageClicked">
        <img :src="'/bn/' + images[imageIndex] || ''" :alt="images[imageIndex] || 'empty'">
        <template v-if="images.length > 1">
          <div class="btn" @click="switchImg" v-if="imageIndex < images.length - 1">
            <img src="../assets/img/arrow.svg" alt="">
          </div>
          <div class="btn translated" @click="switchImg(true)" v-if="imageIndex > 0">
            <img src="../assets/img/arrow.svg" alt="">
          </div>
        </template>
      </div>
    </transition>
  </section>
</template>

<script>
import api from "../api/api";
import BankCard from "../components/BankCard";

export default {
  name: "List",
  components: {BankCard},
  data() {
    return {
      activeBanks: [],
      closedBanks: [],
      importantsList: {ua: [], en: []},
      permanentsList: {ua: [], en: []},
      text: {
        active: {ua: 'Активнi збори', en: 'Active collections'},
        closed: {ua: 'Закритi збори', en: 'Closed collections'},
        list: {ua: 'Список необхiдних речей', en: 'List of necessary things'},
        needed: {ua: 'Необхiднi', en: 'Needed'},
        urgent: {ua: 'термiново', en: 'urgently'},
        constant: {ua: 'постiйно', en: 'constantly'}
      },
      imageClicked: false,
      images: [],
      imageIndex: -1
    }
  },
  created() {
    api.getData('get/l').then(r => {
      if (!r.data.error) {
        // console.log(r)
        const banks = r.data.list.banks || []
        for (let i = 0; i < banks.length; i++) {
          if (banks[i].closed) this.closedBanks.push(banks[i])
          else this.activeBanks.push(banks[i])
        }
        this.closedBanks.reverse()
        const needs = r.data.list.needs
        if (needs.important) {
          this.importantsList.ua = needs.important.ua.split(',')
          this.importantsList.en = needs.important.en.split(',')
        }
        if (needs.permanent) {
          this.permanentsList.ua = needs.permanent.ua.split(',')
          this.permanentsList.en = needs.permanent.en.split(',')
        }
      }
    })
  },
  methods: {
    onImgClick(images) {
      this.images = images.split(',')
      this.imageIndex = 0
      this.imageClicked = true
    },
    closeImg() {
      this.imageClicked = false
      this.imageIndex = -1
      this.images = []
    },
    switchImg(toPrev) {
      if (toPrev === true) this.imageIndex--
      else this.imageIndex++
    }
  }
}
</script>

<style scoped lang="scss" src="../assets/scss/list.scss"></style>
